/**
 *
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */

export const CONTEXTUAL = 'contextual';
export const POPUP = 'popup';
export const TOASTER = 'toaster';

export default {
  CONTEXTUAL,
  POPUP,
  TOASTER
};
